<template>
  <div>
    <p v-for="(subscriptor, i) in subscriptores" :key="i">{{ subscriptor }}</p>
  </div>
</template>

<script>
import firebase from "firebase";
export default {
  name: "Subscriptores",
  data() {
    return {
      subscriptores: [],
    };
  },
  mounted() {
    firebase
      .firestore()
      .collection("subscriptores")
      .onSnapshot((snapshot) => {
        const subscriptores = [];
        snapshot.forEach((doc) => {
          subscriptores.push(doc.data().email);
        });
        this.subscriptores = subscriptores;
      });
  },
};
</script>

<style></style>
