<template>
  <v-container class="mx-auto">
    <v-container>
      <h1 class="mb-5">Agregar una experiencia:</h1>
      <v-row>
        <v-col cols="12" sm="2">
          <v-checkbox
            :color="colors.mainPurple"
            v-model="experiencia.privado"
            label="Privado (Chárter) "
          />
        </v-col>

        <v-col cols="12" sm="2">
          <v-checkbox
            :color="colors.mainPurple"
            v-model="experiencia.reserva"
            label="Preventa"
          />
        </v-col>

        <v-col cols="4" sm="2">
          <v-checkbox
            :color="colors.mainPurple"
            v-model="experiencia.itauExperiencia"
            label="Experiencia ITAU"
        /></v-col>

        <v-col cols="12" sm="2">
          <v-checkbox
            :color="colors.mainPurple"
            v-model="experiencia.sobrevuelo"
            label="El viaje es un sobrevuelo"
          />
        </v-col>

        <v-col cols="12" sm="2">
          <v-checkbox
            :color="colors.mainPurple"
            v-model="experiencia.oculta"
            label="Ocultar experiencia"
          />
        </v-col>

        <v-col cols="12" md="6">
          <v-combobox
            dense
            outlined
            :color="colors.mainPurple"
            v-model="experiencia.origen"
            label="Ciudad origen"
            :items="soloDestinos"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-combobox
            dense
            outlined
            :color="colors.mainPurple"
            v-model="experiencia.destino"
            label="Destino de experiencia"
            :items="soloDestinosExperiencias"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            dense
            outlined
            :color="colors.mainPurple"
            v-model="experiencia.aerodromoOrigen"
            label="Aerodromo origen"
            :items="soloDestinos"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            dense
            outlined
            :color="colors.mainPurple"
            v-model="experiencia.aerodromoDestino"
            label="Aerodromo destino"
            :items="soloDestinosExperiencias"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            dense
            outlined
            :color="colors.mainPurple"
            v-model="experiencia.direccionOrigen"
            label="Dirección origen"
            :items="soloDestinos"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            dense
            outlined
            :color="colors.mainPurple"
            v-model="experiencia.direccionDestino"
            label="Dirección destino"
            :items="soloDestinosExperiencias"
          />
        </v-col>
        <v-col cols="12" md="2">
          <v-select
            dense
            outlined
            :color="colors.mainPurple"
            v-model="experiencia.operador"
            label="Operador"
            :items="nombresOperadores"
          ></v-select>
        </v-col>
        <v-col cols="12" md="2">
          <v-select
            dense
            outlined
            :color="colors.mainPurple"
            v-model="experiencia.aeronave"
            label="Aeronave"
            :items="aeronaves"
          ></v-select>
        </v-col>
        <v-col cols="6" md="2">
          <v-text-field
            dense
            outlined
            :color="colors.mainPurple"
            v-model="experiencia.capacidad"
            type="number"
            label="Capacidad"
          ></v-text-field>
        </v-col>
        <v-col cols="6" md="2">
          <v-text-field
            dense
            outlined
            :color="colors.mainPurple"
            v-model="experiencia.precio"
            type="number"
            label="Precio"
          ></v-text-field>
        </v-col>
        <v-col cols="6" md="2">
          <v-text-field
            dense
            outlined
            :color="colors.mainPurple"
            v-model.number="experiencia.precioEnDolares"
            type="number"
            label="Precio en DOLARES"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="2">
          <v-select
            dense
            outlined
            :color="colors.mainPurple"
            v-model="experiencia.zona"
            label="Zona"
            :items="zonas"
          ></v-select>
        </v-col>
        <v-col cols="6" md="2">
          <v-text-field
            dense
            outlined
            :color="colors.mainPurple"
            v-model.number="experiencia.tiempoDeVuelo"
            type="number"
            label="Tiempo de vuelo"
          ></v-text-field>
        </v-col>
        <v-col cols="6" md="2">
          <v-text-field
            dense
            outlined
            :color="colors.mainPurple"
            v-model.number="experiencia.tiempoTotal"
            type="number"
            label="Tiempo total de Experiencia"
          ></v-text-field>
        </v-col>

        <v-col cols="12" md="12">
          <v-row>
            <v-col cols="12">
              <v-row no-gutters>
                <v-col cols="12">
                  <p>Equipaje permitido:</p>
                  <div class="d-flex align-center">
                    <v-checkbox
                      label="Equipaje de mano"
                      v-model="experiencia.equipaje.mano"
                      :color="colors.mainPurple"
                      class="pr-5"
                    />
                    <v-checkbox
                      label="Equipaje de cabina"
                      v-model="experiencia.equipaje.cabina"
                      :color="colors.mainPurple"
                      class="pr-5"
                    />
                  </div>
                </v-col>
                <v-col cols="12" class=" align-center">
                  Subir nuevas imágenes
                  <div
                    v-for="(imagen, i) in imagenesParaSubir"
                    :key="i"
                    class="row mt-2"
                  >
                    <v-file-input
                      accept="image/*"
                      label="File input"
                      @change="setPreviewImgSelected(i, $event)"
                      v-model="imgSelected"
                    ></v-file-input>

                    <img height="100" :src="imagen.imgSelectedBase64" />
                  </div>
                </v-col>

                <v-col cols="12">
                  <v-btn
                    fab
                    x-small
                    dark
                    @click="addImg"
                    :color="colors.mainPurple"
                    class="mb-7 mx-2"
                    >+</v-btn
                  >
                  <v-btn
                    fab
                    x-small
                    dark
                    @click="lessImg"
                    color="red"
                    class="mb-7 mx-2"
                    >-</v-btn
                  >
                </v-col>

                <v-col cols="12" md="6">
                  <ul>
                    <li
                      v-for="(img, i) of imagenes"
                      :key="i"
                      class="d-flex align-center pb-2"
                    >
                      <img :src="img" height="40" /><v-btn
                        fab
                        x-small
                        dark
                        @click="imagenes.splice(i, 1)"
                        color="red"
                        ><v-icon>mdi-delete</v-icon></v-btn
                      >
                    </li>
                  </ul>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="9">
              <v-textarea
                dense
                outlined
                :color="colors.mainPurple"
                v-model="experiencia.descripcion"
                label="Descripcion tarjeta"
                rows="2"
              ></v-textarea>
              <v-textarea
                dense
                outlined
                :color="colors.mainPurple"
                v-model="experiencia.descripcionEN"
                label="Descripcion tarjeta en Inglés"
                rows="2"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-row no-gutters>
                <v-col cols="12" md="6" class="d-flex align-center">
                  <v-text-field
                    dense
                    outlined
                    :color="colors.mainPurple"
                    v-model="experiencia.detalle"
                    label="Detalles"
                    @keyup.enter="addDetalle"
                  ></v-text-field>
                  <v-btn
                    fab
                    x-small
                    dark
                    @click="addDetalle"
                    :color="colors.mainPurple"
                    class="mb-7 ml-2"
                    >+</v-btn
                  >
                </v-col>
                <v-col cols="12" md="6">
                  <ul>
                    <li
                      v-for="(detalle, i) of detalles"
                      :key="i"
                      class="d-flex align-center pb-2"
                    >
                      {{ detalle }}
                      <v-btn
                        fab
                        x-small
                        dark
                        color="red"
                        @click="detalles.splice(i, 1)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </li>
                  </ul>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" md="6" class="d-flex align-center">
                  <v-text-field
                    dense
                    outlined
                    :color="colors.mainPurple"
                    v-model="experiencia.detalleEN"
                    label="Detalles en Inglés"
                    @keyup.enter="addDetalleEN"
                  ></v-text-field>
                  <v-btn
                    fab
                    x-small
                    dark
                    @click="addDetalleEN"
                    :color="colors.mainPurple"
                    class="mb-7 ml-2"
                    >+</v-btn
                  >
                </v-col>
                <v-col cols="12" md="6">
                  <ul>
                    <li
                      v-for="(detalle, i) of experiencia.detallesEN"
                      :key="i"
                      class="d-flex align-center pb-2"
                    >
                      {{ detalle }}
                      <v-btn
                        fab
                        x-small
                        dark
                        color="red"
                        @click="experiencia.detallesEN.splice(i, 1)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </li>
                  </ul>
                </v-col>
              </v-row>

              <h3>Fechas:</h3>
              <v-row>
                <v-col cols="5">
                  <v-date-picker
                    v-model="experiencia.fechas"
                    multiple
                    color="#645dc7"
                  ></v-date-picker>
                </v-col>
                <v-col>
                  <v-chip
                    class="mx-3 mb-3"
                    close
                    @click:close="eliminarFehca(i)"
                    v-for="(fecha, i) in experiencia.fechas"
                    :key="i"
                  >
                    {{ fecha }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <h4>Seleccionar por días:</h4>

          <v-row>
            <v-checkbox
              v-for="({ label }, i) in experiencia.diasDisponibles"
              :key="i"
              v-model="experiencia.diasDisponibles[i].value"
              :label="label"
              class="mr-4"
            ></v-checkbox>
          </v-row>

          <v-btn
            depressed
            color="primary"
            class="mr-3"
            @click="setDiasDisponibles(true)"
          >
            Seleccionar todos
          </v-btn>
          <v-btn depressed color="error" @click="setDiasDisponibles(false)">
            Quitar todos
          </v-btn>
        </v-col>
      </v-row>
      <h3 class="pt-10">Bloqueo de fechas por temporada:</h3>
      <v-row>
        <v-col cols="5">
          <v-date-picker
            color="red"
            v-model="experiencia.fechasBloqueadas"
            range
            locale="es"
          ></v-date-picker>
        </v-col>
        <v-col>
          <v-chip
            class="mx-3 mb-3"
            close
            @click:close="eliminarFehcaBloqueada(i)"
            v-for="(fecha, i) in experiencia.fechasBloqueadas"
            :key="i"
          >
            {{ fecha }}
          </v-chip>
        </v-col>
      </v-row>
    </v-container>
    <v-btn
      @click="addExperiencia"
      dark
      rounded
      width="100%"
      class="mt-6"
      :color="colors.mainPurple"
      >Agregar experiencia</v-btn
    >
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import utils from "@/helpers/utils";
const { formatString } = utils;
import firebase from "firebase";

export default {
  name: "AgregarExperiencia",
  data() {
    return {
      diasDisponibles: [
        { label: "lunes", value: false },
        { label: "martes", value: false },
        { label: "miercoles", value: false },
        { label: "jueves", value: false },
        { label: "viernes", value: false },
        { label: "sabado", value: false },
        { label: "domingo", value: false },
      ],
      loading: false,
      imagenesParaSubir: [{}],
      imagenSeleccionadaBase64: "",
      imgSelected: null,
      experiencia: {
        diasDisponibles: [
          { label: "lunes", value: false },
          { label: "martes", value: false },
          { label: "miercoles", value: false },
          { label: "jueves", value: false },
          { label: "viernes", value: false },
          { label: "sabado", value: false },
          { label: "domingo", value: false },
        ],
        fechas: [],
        aeronave: "",
        aerodromoOrigen: "",
        aerodromoDestino: "",
        capacidad: "",
        descripcion: "",
        descripcionEN: "",
        destino: "",
        direccionDestino: "",
        direccionOrigen: "",
        equipaje: {
          mano: false,
          cabina: false,
          extra: false,
        },
        fecha: "",
        horaDespegue: "",
        horaLlegada: "",
        img: [],
        detalle: "",
        detallesEN: "",
        operador: "",
        origen: "",
        precio: "",
        precioEnDolares: "",
        sobrevuelo: false,
        reserva: true,
        status: "Abierto",
        ventas: 0,
      },
      imagenes: [],
      detalles: [],
      aeronaves: ["Avión", "Helicóptero"],
      zonas: [
        "Norte grande",
        "Norte chico",
        "Centro",
        "Sur",
        "Austral",
        "Insular",
      ],
    };
  },
  methods: {
    ...mapActions("Experiencias", ["agregarExperiencia"]),

    setDiasDisponibles(val) {
      for (let p of this.experiencia.diasDisponibles) {
        p.value = val;
      }
    },
    eliminarFehca(i) {
      this.experiencia.fechas.splice(i, 1);
    },

    eliminarFehcaBloqueada(i) {
      this.experiencia.fechasBloqueadas.splice(i, 1);
    },

    async setPreviewImgSelected(i, image) {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = () => {
        const { result: imgSelectedBase64 } = reader;
        const imagen = { image, imgSelectedBase64 };
        this.imagenesParaSubir[i] = imagen;
      };
    },

    async addExperiencia() {
      //convertir a numero
      this.experiencia.precio = +this.experiencia.precio;
      this.experiencia.capacidad = +this.experiencia.capacidad;
      //Imagenes y detalles
      this.experiencia.img = this.imagenes;
      this.experiencia.detalles = this.detalles;
      //formatear en minusculas y sin tildes
      this.experiencia.aeronave = formatString(this.experiencia.aeronave);
      //get operador id
      this.experiencia.operador = this.getOperadorByName(
        this.experiencia.operador
      );

      if (this.experiencia.operador) {
        delete this.experiencia.operador.rating;
        delete this.experiencia.operador.calificaciones;
        delete this.experiencia.detalle;
      }
      await this.agregarExperiencia(
        JSON.parse(JSON.stringify(this.experiencia))
      );

      this.experiencia.aeronave = "";
      this.experiencia.capacidad = "";
      this.experiencia.descripcion = "";
      this.experiencia.descripcionEN = "";
      this.experiencia.destino = "";
      this.experiencia.direccionDestino = "";
      this.experiencia.direccionOrigen = "";
      this.experiencia.aerodromoDestino = "";
      this.experiencia.aerodromoOrigen = "";
      this.experiencia.fecha = "";
      this.experiencia.horaDespegue = "";
      this.experiencia.horaLlegada = "";
      this.experiencia.img = "";
      this.experiencia.detalle = "";
      this.experiencia.operador = "";
      this.experiencia.origen = "";
      this.experiencia.precio = "";
      this.experiencia.sobrevuelo = false;
      this.experiencia.equipaje.mano = false;
      this.experiencia.equipaje.cabina = false;
      this.imagenes = [];
      this.detalles = [];
    },

    async uploadImages() {
      const { imagenesParaSubir } = this;
      const storageRef = firebase.storage().ref();

      for (const imagen of imagenesParaSubir) {
        const { image } = imagen;
        if (image) {
          const { name } = image;
          const carpetaImagenes = storageRef.child(
            `assets/ImagenesVuelos/${name}`
          );
          await carpetaImagenes.put(image);
          const urlImage = await carpetaImagenes.getDownloadURL();
          this.experiencia.img.push(urlImage);
        }
      }

      return true;
    },

    addImg() {
      this.imagenesParaSubir.push({});
    },
    lessImg() {
      this.imagenesParaSubir.pop();
    },
    addDetalle() {
      this.detalles.push(this.experiencia.detalle);
      this.experiencia.detalle = "";
    },
    addDetalleEN() {
      if (!this.experiencia.detallesEN) this.experiencia.detallesEN = [];
      this.experiencia.detallesEN.push(this.experiencia.detalleEN);
      this.experiencia.detalleEN = "";
    },
    sobrevuelo() {
      if (this.experiencia.sobrevuelo) {
        this.experiencia.direccionDestino = this.experiencia.direccionOrigen;
        this.experiencia.aerodromoDestino = this.experiencia.aerodromoOrigen;
      }
    },
  },
  computed: {
    ...mapState(["colors"]),
    ...mapGetters("Experiencias", ["soloDestinosExperiencias"]),
    ...mapGetters("Operadores", ["getOperadorByName"]),
    ...mapGetters("Vuelos", ["soloDestinos"]),
    ...mapState("Operadores", ["operadores"]),
    nombresOperadores() {
      const nombresOperadores = this.operadores.map((o) => o.nombre);

      return nombresOperadores;
    },
  },
  title() {
    return `Agregar experiencia - HummingBird`;
  },
};
</script>

<style></style>
